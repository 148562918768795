// 车辆信息列表表头
export const carInfoColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '车牌号码',
      width: 100,
      align: 'center',
      dataIndex: 'platNumber'
    },
    {
      title: '名称',
      width: 100,
      align: 'center',
      dataIndex: 'fullName'
    },
    {
      title: '所属部门',
      width: 100,
      align: 'center',
      dataIndex: 'department.name'
    },
    {
      title: '用途',
      width: 80,
      align: 'center',
      dataIndex: 'useType',
      scopedSlots: { customRender: 'useType' }
    },
    {
      title: '载重',
      width: 80,
      align: 'center',
      dataIndex: 'volume',
      scopedSlots: { customRender: 'volume' }
    },
    {
      title: '设备号',
      width: 120,
      align: 'center',
      dataIndex: 'macId'
    },
    // {
    //   title: '设备型号',
    //   width: 90,
    //   align: 'center',
    //   dataIndex: 'macName'
    // },
    {
      title: '车架号',
      width: 200,
      align: 'center',
      dataIndex: 'frameNumber'
    },
    {
      title: '购买时间',
      width: 110,
      align: 'center',
      dataIndex: 'buyDate'
    },
    {
      title: '到期时间',
      width: 110,
      align: 'center',
      dataIndex: 'blockDate'
    },
    {
      title: 'sim卡号',
      width: 120,
      align: 'center',
      dataIndex: 'sim'
    },
    {
      title: '操作',
      width: 160,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 车辆任务状态
export const statusList = function () {
  return [
    { value: 'FREE', label: '空闲' },
    { value: 'GOING', label: '进行中' }
  ]
}
// 车辆用途类型
export const useList = function () {
  return [
    { value: 'OTHER', label: '其他' },
    { value: 'QY', label: '清运' },
    { value: 'XJ', label: '巡检' }
  ]
}

