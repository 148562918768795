<template>
  <a-spin :spinning="spinShow">
    <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
      <a-descriptions-item label="所属部门">{{formItem.carInfo.department.name||'-'}}</a-descriptions-item>
      <a-descriptions-item label="车牌号码">{{formItem.carInfo.platNumber}}</a-descriptions-item>
      <a-descriptions-item label="车辆名称">{{formItem.carInfo.fullName}}</a-descriptions-item>
      <a-descriptions-item label="设备号码">{{formItem.carInfo.macId||'-'}}</a-descriptions-item>
      <a-descriptions-item label="设备型号">{{formItem.carInfo.macName}}</a-descriptions-item>
      <a-descriptions-item label="购买时间">{{formItem.carInfo.buyDate || '-'}}</a-descriptions-item>
      <a-descriptions-item label="到期时间">{{formItem.carInfo.blockDate}}</a-descriptions-item>
      <a-descriptions-item label="sim卡号">{{ formItem.carInfo.sim}}</a-descriptions-item>
      <a-descriptions-item label="车辆载重">{{ formItem.carInfo.volume || '-'}}吨</a-descriptions-item>
      <a-descriptions-item label="车辆用途">{{ formItem.carInfo.useType ==='QY' ? '清运': formItem.carInfo.useType==='XJ'? '巡检': '-'}}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions :column="2" style="margin-left: 21px">
      <a-descriptions-item label="图片信息" v-if="formItem.images.length">
        <template>
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.images" style="margin-top: -15px"/>
        </template>
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { carInfoFormVO } from './common/carInfoFormVO'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CarInfoDetail',
    components: { QiniuImageUpload },
    data() {
      return {
        spinShow: false,
        formItem: carInfoFormVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 }
      }
    },
    methods: {
      loadData(id) {
        this.formItem = carInfoFormVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.view,
          params: { id },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              Object.assign(this.formItem, data.body)
              this.spinShow = false
            })
          }
        })
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: "Lantinghei SC", "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;
  }
</style>